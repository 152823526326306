.str-video__menu-container {
  z-index: 2;
  width: max-content;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}
