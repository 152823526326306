body,
body > * {
  margin: 0;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
p,
textarea,
input {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--str-video__base-color4);
  color: var(--str-video__text-color1);
}

.str-video {
  background-color: var(--str-video__base-color4);
  color: var(--str-video__text-color1);
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

.rd__call {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.5rem;
  overflow-x: hidden;
  background-color: var(--str-video__base-color4);

  .rd__stage {
    flex: 1;
    min-height: 0;
    padding-inline: 0.375rem;
  }

  .rd__main-call-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .str-video__permission-requests {
      width: 100%;
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.rd__stage-pip {
  padding-block: 1rem;
  min-height: 0;
  flex-grow: 1;
}

button.rd__button.rd__stop-pip {
  margin: auto;
  width: auto;
}
