.str-video__pip-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;
  gap: 1rem;
  padding-inline: 1rem;
  height: 100%;
}
